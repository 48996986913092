import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="footer-wrapper">
          <div className="footer-top">
            <div className="footer-top-left">
              <ul className="footer-list">
                <li><Link to="https://www.instagram.com/epargnerensemble">Contact</Link></li>
                <li><Link to="/mentions-legales/">Mentions légales</Link></li>
                <li><Link to="/info/">Info sur ÉpargnerEnsemble</Link></li>
              </ul>
            </div>
            <div className="footer-top-right">
              <ul className="footer-list">
                <li className="list-social-icon">
                  <Link to="https://www.instagram.com/epargnerensemble" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"><path id="Shape" d="M0,11.071A11.1,11.1,0,0,1,11.071,0h8.857A11.1,11.1,0,0,1,31,11.071v8.857A11.1,11.1,0,0,1,19.929,31H11.071A11.1,11.1,0,0,1,0,19.929ZM15.5,23.25A7.75,7.75,0,1,0,7.75,15.5,7.7,7.7,0,0,0,15.5,23.25Zm0-2.214A5.536,5.536,0,1,1,21.036,15.5,5.482,5.482,0,0,1,15.5,21.036ZM23.8,8.857A1.7,1.7,0,0,0,25.464,7.2a1.661,1.661,0,1,0-3.321,0A1.7,1.7,0,0,0,23.8,8.857Z"></path></svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-mid">
            <p> Le contenu de ce site ne doit pas être considéré comme un conseil financier.<br /> 
            Nous ne sommes ni responsable pour tes investissements, ni le contenu des autres sites partager en lien.<br />
            L'investissement en bourse supporte un risque de perte en capital puisque leur valeur est sujette à fluctuation <br /> à la hausse comme à la baisse dépendant notamment de l'évolution des marchés financiers.<br />
            Les performances passées ne préjugent pas des performances futures et ne sont pas constantes dans le temps.<br /> 
            Certain lien sur ce sont sont des liens d'affiliation. EpargnerEnsemble peut recevoir une commission mais cela n'impacte pas votre experience.
            </p>
          </div>
          <div className="footer-bot">
            <p>© 2021 ÉpargnerEnsemble.</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
